<template>
    <div class="test">
        <div class="list">
          <div class="title">题目每道10分</div>
        </div>
        <Empty title="暂无测试成绩" v-if="records.length == 0" />
        <div class="list">
         <div class="loadMore" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">

            <router-link
              v-for="it in records"
              :key="it.answerId"
              :to="`/testDetail/${it.answerId}?score=${it.score}`" 
              tag="div" 
              class="item"
            >
              <p>
                <span>{{it.subjectCateName}}</span>
                <span>成绩：{{it.score}}分</span>
                <i class="list-arrow" />
              </p>
            </router-link>

         </div>

      </div>
    </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';
import Empty from '@/components/Empty';
export default {
  name: 'Home',
  components: {
    Empty
  },
  filters: {
    capitalize: function (value) {
      if (!value) return 0
      return value.toFixed(2)
    }
  },
  data () {
      return {
        records: [],
        busy: false,
        current: 1,
        size: 10,
      }
  },
  beforeMount() {
      document.title = '测试成绩';
  },
  mounted() {
    this.records= [];
  },
  methods: {
    loadMore() {
      this.$http.post('/api/answer/test/pageList', {
        size: this.size,
        current: this.current,
      })
      .then((res) => {
        let data = res.data.data;
        if (this.records.length > 0 && data.records.length === 0) {
          this.busy = true;
          return;
        };
        this.records = this.records.concat(data.records);
        this.current ++;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
  }

}
</script>

<style lang="less" scoped>
    .test {
      .list {
        background-color: white;
        color: #333333;
        font-size: 0.32rem;
        .title {
          height: 0.9rem;
          line-height: 0.9rem;
          padding-left: 0.2rem;
          border-bottom: 1px solid #cccccc;
        }
        .loadMore {
          height: calc(100vh - 0.9rem);
        }
        .item {
          height: 0.9rem;
          line-height: 0.9rem;
          padding: 0 0.8rem 0 0.4rem;
          box-sizing: border-box;
          border-bottom: 1px solid #cccccc;
          position: relative;
          p {
            display: flex;
            justify-content: space-between;
            font-size: 0.29rem;
          }
          .list-arrow {
            position: absolute;
            right: 0.3rem;
            top: 50%;
            transform: translateY(-50%);
            width: 0.39rem;
            height: 0.39rem;
            background-image: url('../../image/second/arrow1.png');
            background-size: 100% 100%;
            display: block;
          }
        }
      }
    }
</style>
